export const AboutExpect = () => import('../../components/about/AboutExpect.vue' /* webpackChunkName: "components/about-expect" */).then(c => wrapFunctional(c.default || c))
export const AboutFormats = () => import('../../components/about/AboutFormats.vue' /* webpackChunkName: "components/about-formats" */).then(c => wrapFunctional(c.default || c))
export const AboutGreet = () => import('../../components/about/AboutGreet.vue' /* webpackChunkName: "components/about-greet" */).then(c => wrapFunctional(c.default || c))
export const AboutMission = () => import('../../components/about/AboutMission.vue' /* webpackChunkName: "components/about-mission" */).then(c => wrapFunctional(c.default || c))
export const AboutParticipant = () => import('../../components/about/AboutParticipant.vue' /* webpackChunkName: "components/about-participant" */).then(c => wrapFunctional(c.default || c))
export const ChatPrivate = () => import('../../components/chat/ChatPrivate.vue' /* webpackChunkName: "components/chat-private" */).then(c => wrapFunctional(c.default || c))
export const ExhibitorsExhibitor = () => import('../../components/exhibitors/ExhibitorsExhibitor.vue' /* webpackChunkName: "components/exhibitors-exhibitor" */).then(c => wrapFunctional(c.default || c))
export const ContestAbout = () => import('../../components/contest/ContestAbout.vue' /* webpackChunkName: "components/contest-about" */).then(c => wrapFunctional(c.default || c))
export const ContestFinalists = () => import('../../components/contest/ContestFinalists.vue' /* webpackChunkName: "components/contest-finalists" */).then(c => wrapFunctional(c.default || c))
export const ContestSemifinalists = () => import('../../components/contest/ContestSemifinalists.vue' /* webpackChunkName: "components/contest-semifinalists" */).then(c => wrapFunctional(c.default || c))
export const ContestStages = () => import('../../components/contest/ContestStages.vue' /* webpackChunkName: "components/contest-stages" */).then(c => wrapFunctional(c.default || c))
export const AccountContestForm = () => import('../../components/account/AccountContestForm.vue' /* webpackChunkName: "components/account-contest-form" */).then(c => wrapFunctional(c.default || c))
export const AccountHeader = () => import('../../components/account/AccountHeader.vue' /* webpackChunkName: "components/account-header" */).then(c => wrapFunctional(c.default || c))
export const AccountRecSessions = () => import('../../components/account/AccountRecSessions.vue' /* webpackChunkName: "components/account-rec-sessions" */).then(c => wrapFunctional(c.default || c))
export const AccountRecUsers = () => import('../../components/account/AccountRecUsers.vue' /* webpackChunkName: "components/account-rec-users" */).then(c => wrapFunctional(c.default || c))
export const AccountTabs = () => import('../../components/account/AccountTabs.vue' /* webpackChunkName: "components/account-tabs" */).then(c => wrapFunctional(c.default || c))
export const FaqItem = () => import('../../components/faq/FaqItem.vue' /* webpackChunkName: "components/faq-item" */).then(c => wrapFunctional(c.default || c))
export const HowToGetMap = () => import('../../components/how-to-get/HowToGetMap.vue' /* webpackChunkName: "components/how-to-get-map" */).then(c => wrapFunctional(c.default || c))
export const AppBackBtn = () => import('../../components/app/AppBackBtn.vue' /* webpackChunkName: "components/app-back-btn" */).then(c => wrapFunctional(c.default || c))
export const AppBtn = () => import('../../components/app/AppBtn.vue' /* webpackChunkName: "components/app-btn" */).then(c => wrapFunctional(c.default || c))
export const AppContent = () => import('../../components/app/AppContent.vue' /* webpackChunkName: "components/app-content" */).then(c => wrapFunctional(c.default || c))
export const AppCropper = () => import('../../components/app/AppCropper.vue' /* webpackChunkName: "components/app-cropper" */).then(c => wrapFunctional(c.default || c))
export const AppEmpty = () => import('../../components/app/AppEmpty.vue' /* webpackChunkName: "components/app-empty" */).then(c => wrapFunctional(c.default || c))
export const AppField = () => import('../../components/app/AppField.vue' /* webpackChunkName: "components/app-field" */).then(c => wrapFunctional(c.default || c))
export const AppFooter = () => import('../../components/app/AppFooter.vue' /* webpackChunkName: "components/app-footer" */).then(c => wrapFunctional(c.default || c))
export const AppForm = () => import('../../components/app/AppForm.vue' /* webpackChunkName: "components/app-form" */).then(c => wrapFunctional(c.default || c))
export const AppGallery = () => import('../../components/app/AppGallery.vue' /* webpackChunkName: "components/app-gallery" */).then(c => wrapFunctional(c.default || c))
export const AppIcon = () => import('../../components/app/AppIcon.vue' /* webpackChunkName: "components/app-icon" */).then(c => wrapFunctional(c.default || c))
export const AppIframeVideo = () => import('../../components/app/AppIframeVideo.vue' /* webpackChunkName: "components/app-iframe-video" */).then(c => wrapFunctional(c.default || c))
export const AppInitials = () => import('../../components/app/AppInitials.vue' /* webpackChunkName: "components/app-initials" */).then(c => wrapFunctional(c.default || c))
export const AppLayout = () => import('../../components/app/AppLayout.vue' /* webpackChunkName: "components/app-layout" */).then(c => wrapFunctional(c.default || c))
export const AppLoadingScreen = () => import('../../components/app/AppLoadingScreen.vue' /* webpackChunkName: "components/app-loading-screen" */).then(c => wrapFunctional(c.default || c))
export const AppLocaleSwitch = () => import('../../components/app/AppLocaleSwitch.vue' /* webpackChunkName: "components/app-locale-switch" */).then(c => wrapFunctional(c.default || c))
export const AppLogo = () => import('../../components/app/AppLogo.vue' /* webpackChunkName: "components/app-logo" */).then(c => wrapFunctional(c.default || c))
export const AppModal = () => import('../../components/app/AppModal.vue' /* webpackChunkName: "components/app-modal" */).then(c => wrapFunctional(c.default || c))
export const AppPaginator = () => import('../../components/app/AppPaginator.vue' /* webpackChunkName: "components/app-paginator" */).then(c => wrapFunctional(c.default || c))
export const AppPdfThumbnail = () => import('../../components/app/AppPdfThumbnail.vue' /* webpackChunkName: "components/app-pdf-thumbnail" */).then(c => wrapFunctional(c.default || c))
export const AppRabbet = () => import('../../components/app/AppRabbet.vue' /* webpackChunkName: "components/app-rabbet" */).then(c => wrapFunctional(c.default || c))
export const AppResetFiltersBtn = () => import('../../components/app/AppResetFiltersBtn.vue' /* webpackChunkName: "components/app-reset-filters-btn" */).then(c => wrapFunctional(c.default || c))
export const AppShare = () => import('../../components/app/AppShare.vue' /* webpackChunkName: "components/app-share" */).then(c => wrapFunctional(c.default || c))
export const AppSimpleBtn = () => import('../../components/app/AppSimpleBtn.vue' /* webpackChunkName: "components/app-simple-btn" */).then(c => wrapFunctional(c.default || c))
export const AppSpinner = () => import('../../components/app/AppSpinner.vue' /* webpackChunkName: "components/app-spinner" */).then(c => wrapFunctional(c.default || c))
export const AppSwiper = () => import('../../components/app/AppSwiper.vue' /* webpackChunkName: "components/app-swiper" */).then(c => wrapFunctional(c.default || c))
export const AppTabs = () => import('../../components/app/AppTabs.vue' /* webpackChunkName: "components/app-tabs" */).then(c => wrapFunctional(c.default || c))
export const AppTime = () => import('../../components/app/AppTime.vue' /* webpackChunkName: "components/app-time" */).then(c => wrapFunctional(c.default || c))
export const AppTitle = () => import('../../components/app/AppTitle.vue' /* webpackChunkName: "components/app-title" */).then(c => wrapFunctional(c.default || c))
export const AppToasts = () => import('../../components/app/AppToasts.vue' /* webpackChunkName: "components/app-toasts" */).then(c => wrapFunctional(c.default || c))
export const AppUser = () => import('../../components/app/AppUser.vue' /* webpackChunkName: "components/app-user" */).then(c => wrapFunctional(c.default || c))
export const IndexAbout = () => import('../../components/index/IndexAbout.vue' /* webpackChunkName: "components/index-about" */).then(c => wrapFunctional(c.default || c))
export const IndexBidModal = () => import('../../components/index/IndexBidModal.vue' /* webpackChunkName: "components/index-bid-modal" */).then(c => wrapFunctional(c.default || c))
export const IndexCongrats = () => import('../../components/index/IndexCongrats.vue' /* webpackChunkName: "components/index-congrats" */).then(c => wrapFunctional(c.default || c))
export const IndexCoorganizers = () => import('../../components/index/IndexCoorganizers.vue' /* webpackChunkName: "components/index-coorganizers" */).then(c => wrapFunctional(c.default || c))
export const IndexDesc = () => import('../../components/index/IndexDesc.vue' /* webpackChunkName: "components/index-desc" */).then(c => wrapFunctional(c.default || c))
export const IndexHero = () => import('../../components/index/IndexHero.vue' /* webpackChunkName: "components/index-hero" */).then(c => wrapFunctional(c.default || c))
export const IndexNetworking = () => import('../../components/index/IndexNetworking.vue' /* webpackChunkName: "components/index-networking" */).then(c => wrapFunctional(c.default || c))
export const IndexRegBanner = () => import('../../components/index/IndexRegBanner.vue' /* webpackChunkName: "components/index-reg-banner" */).then(c => wrapFunctional(c.default || c))
export const IndexReviews = () => import('../../components/index/IndexReviews.vue' /* webpackChunkName: "components/index-reviews" */).then(c => wrapFunctional(c.default || c))
export const IndexSpeaker = () => import('../../components/index/IndexSpeaker.vue' /* webpackChunkName: "components/index-speaker" */).then(c => wrapFunctional(c.default || c))
export const IndexSpeakers = () => import('../../components/index/IndexSpeakers.vue' /* webpackChunkName: "components/index-speakers" */).then(c => wrapFunctional(c.default || c))
export const IndexStats = () => import('../../components/index/IndexStats.vue' /* webpackChunkName: "components/index-stats" */).then(c => wrapFunctional(c.default || c))
export const IndexTracks = () => import('../../components/index/IndexTracks.vue' /* webpackChunkName: "components/index-tracks" */).then(c => wrapFunctional(c.default || c))
export const LivePlayer = () => import('../../components/live/LivePlayer.vue' /* webpackChunkName: "components/live-player" */).then(c => wrapFunctional(c.default || c))
export const MentorsMeetingModal = () => import('../../components/mentors/MentorsMeetingModal.vue' /* webpackChunkName: "components/mentors-meeting-modal" */).then(c => wrapFunctional(c.default || c))
export const MentorsMentor = () => import('../../components/mentors/MentorsMentor.vue' /* webpackChunkName: "components/mentors-mentor" */).then(c => wrapFunctional(c.default || c))
export const PartnersPartner = () => import('../../components/partners/PartnersPartner.vue' /* webpackChunkName: "components/partners-partner" */).then(c => wrapFunctional(c.default || c))
export const NetworkingCard = () => import('../../components/networking/NetworkingCard.vue' /* webpackChunkName: "components/networking-card" */).then(c => wrapFunctional(c.default || c))
export const ProgramDays = () => import('../../components/program/ProgramDays.vue' /* webpackChunkName: "components/program-days" */).then(c => wrapFunctional(c.default || c))
export const ProgramSchedule = () => import('../../components/program/ProgramSchedule.vue' /* webpackChunkName: "components/program-schedule" */).then(c => wrapFunctional(c.default || c))
export const ProgramSession = () => import('../../components/program/ProgramSession.vue' /* webpackChunkName: "components/program-session" */).then(c => wrapFunctional(c.default || c))
export const ProgramTrack = () => import('../../components/program/ProgramTrack.vue' /* webpackChunkName: "components/program-track" */).then(c => wrapFunctional(c.default || c))
export const ParticipantsMeetingModal = () => import('../../components/participants/ParticipantsMeetingModal.vue' /* webpackChunkName: "components/participants-meeting-modal" */).then(c => wrapFunctional(c.default || c))
export const PitchesCard = () => import('../../components/pitches/PitchesCard.vue' /* webpackChunkName: "components/pitches-card" */).then(c => wrapFunctional(c.default || c))
export const PitchesStartup = () => import('../../components/pitches/PitchesStartup.vue' /* webpackChunkName: "components/pitches-startup" */).then(c => wrapFunctional(c.default || c))
export const PitchesStat = () => import('../../components/pitches/PitchesStat.vue' /* webpackChunkName: "components/pitches-stat" */).then(c => wrapFunctional(c.default || c))
export const RegistrationForm = () => import('../../components/registration/RegistrationForm.vue' /* webpackChunkName: "components/registration-form" */).then(c => wrapFunctional(c.default || c))
export const RegistrationQuickForm = () => import('../../components/registration/RegistrationQuickForm.vue' /* webpackChunkName: "components/registration-quick-form" */).then(c => wrapFunctional(c.default || c))
export const ChatPublic = () => import('../../components/chat/public/ChatPublic.vue' /* webpackChunkName: "components/chat-public" */).then(c => wrapFunctional(c.default || c))
export const ChatPublicMessage = () => import('../../components/chat/public/ChatPublicMessage.vue' /* webpackChunkName: "components/chat-public-message" */).then(c => wrapFunctional(c.default || c))
export const AccountExhibitionForm = () => import('../../components/account/exhibition/AccountExhibitionForm.vue' /* webpackChunkName: "components/account-exhibition-form" */).then(c => wrapFunctional(c.default || c))
export const AccountScheduleCard = () => import('../../components/account/schedule/AccountScheduleCard.vue' /* webpackChunkName: "components/account-schedule-card" */).then(c => wrapFunctional(c.default || c))
export const AccountScheduleMeetingModal = () => import('../../components/account/schedule/AccountScheduleMeetingModal.vue' /* webpackChunkName: "components/account-schedule-meeting-modal" */).then(c => wrapFunctional(c.default || c))
export const AccountPaymentBill = () => import('../../components/account/payment/AccountPaymentBill.vue' /* webpackChunkName: "components/account-payment-bill" */).then(c => wrapFunctional(c.default || c))
export const AccountPaymentCoupon = () => import('../../components/account/payment/AccountPaymentCoupon.vue' /* webpackChunkName: "components/account-payment-coupon" */).then(c => wrapFunctional(c.default || c))
export const AccountPaymentUser = () => import('../../components/account/payment/AccountPaymentUser.vue' /* webpackChunkName: "components/account-payment-user" */).then(c => wrapFunctional(c.default || c))
export const AppCheckbox = () => import('../../components/app/checkbox/AppCheckbox.vue' /* webpackChunkName: "components/app-checkbox" */).then(c => wrapFunctional(c.default || c))
export const AppCheckboxGroup = () => import('../../components/app/checkbox/AppCheckboxGroup.vue' /* webpackChunkName: "components/app-checkbox-group" */).then(c => wrapFunctional(c.default || c))
export const AppHeader = () => import('../../components/app/header/AppHeader.vue' /* webpackChunkName: "components/app-header" */).then(c => wrapFunctional(c.default || c))
export const AppHeaderBtn = () => import('../../components/app/header/AppHeaderBtn.vue' /* webpackChunkName: "components/app-header-btn" */).then(c => wrapFunctional(c.default || c))
export const IndexLiveHero = () => import('../../components/index/live/IndexLiveHero.vue' /* webpackChunkName: "components/index-live-hero" */).then(c => wrapFunctional(c.default || c))
export const LiveStream = () => import('../../components/live/stream/LiveStream.vue' /* webpackChunkName: "components/live-stream" */).then(c => wrapFunctional(c.default || c))
export const LiveStreamActions = () => import('../../components/live/stream/LiveStreamActions.vue' /* webpackChunkName: "components/live-stream-actions" */).then(c => wrapFunctional(c.default || c))
export const LiveStreamHead = () => import('../../components/live/stream/LiveStreamHead.vue' /* webpackChunkName: "components/live-stream-head" */).then(c => wrapFunctional(c.default || c))
export const SessionParticipants = () => import('../../components/session/participants/SessionParticipants.vue' /* webpackChunkName: "components/session-participants" */).then(c => wrapFunctional(c.default || c))
export const SessionParticipantsItem = () => import('../../components/session/participants/SessionParticipantsItem.vue' /* webpackChunkName: "components/session-participants-item" */).then(c => wrapFunctional(c.default || c))
export const AccountCarPassInfo = () => import('../../components/account/car-pass/info/AccountCarPassInfo.vue' /* webpackChunkName: "components/account-car-pass-info" */).then(c => wrapFunctional(c.default || c))
export const AccountCarPassInfoPdf = () => import('../../components/account/car-pass/info/AccountCarPassInfoPdf.vue' /* webpackChunkName: "components/account-car-pass-info-pdf" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
